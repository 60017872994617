.carousel .slide {
  display: flex;
  height: 500px;
  justify-content: center;
  align-items: center;
  margin: 10px 1px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(14, 13, 13, 0.1); 
  overflow: hidden; /* Ensure the image does not overflow the container */
}

.imgContainerSlider {
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 10px;
  overflow: hidden; /* Ensure the image does not overflow the container */
  position: relative; /* Required for absolute positioning of zoomed image */
}

.carousel .slide img {
  max-height: 100%;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  object-fit: contain; /* Ensures the image covers the container without stretching */
}

/* Zoom effect on hover */
.carousel .slide img:hover {
  transform: scale(1.8); /* Adjust scale as needed */
}

/* Style for overlay zoom effect */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.overlay img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

/* Custom arrow styles */
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2rem;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 2;
}

.custom-prev-arrow {
  left: 0;
}

.custom-next-arrow {
  right: 0;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .carousel .slide {
    width: 120px;
    height: auto; /* Allow height to adjust automatically */
    border-radius: 10px;
  }

  .imgContainerSlider {
    width: 120px; /* Set each image container to a fixed width of 120 pixels */
    height: auto; /* Allow height to adjust automatically */
  }
}
