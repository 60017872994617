.service-card {
  width: 40%;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.service-card-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.service-card-content {
  text-align: center;
  width: 80%;
  background: rgb(3, 52, 137);
  position: absolute;
  bottom: 0;
  color: #fff;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
  max-height: 200px; /* Adjust to fit the initial height of the content */
  opacity: 1;
}

.service-card:hover .service-card-content {
  max-height: 400px; /* Adjust based on content height */
}

#servicecardbutton {
  color: #fff;
  cursor: pointer;
  background: burlywood;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

#servicecardbutton:hover {
  background-color: #f0a500; /* Change button color on hover */
}

#servicecardpara {
  max-height: 0; /* Hide paragraph by default */
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.service-card-content:hover #servicecardpara {
  max-height: 400px; /* Adjust based on content height */
  opacity: 1;
}

/* Optional: Add shadow effect to card on hover */
.service-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}
