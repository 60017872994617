/* Custom Carousel Caption */
.custom-caption {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  color: #fff;
  width: 45%;
  animation: fadeIn 1s ease-in-out;
}

.display-4 {
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  animation: fadeInDown 1.5s ease-in-out;
}

.button-group {
  margin-top: 20px;
  animation: fadeInUp 2s ease-in-out;
}

.custom-button {
  margin-right: 15px;
  padding: 15px 25px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 1rem;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.custom-button:hover {
  background-color: #0056b3;
  color: #fff;
}

.custom-button:active {
  transform: scale(0.95);
}

/* Fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Customizing Carousel Controls */
.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  font-size: 2rem;
  color: #fff;
}

.carousel-indicators li {
  background-color: #007BFF;
}

.carousel-indicators .active {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .custom-caption {
    width: 60%;
  }

  .display-4 {
    font-size: 2.5rem;
  }

  .custom-button {
    padding: 12px 20px;
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .custom-caption {
    width: 70%;
    left: 20px;
  }

  .display-4 {
    font-size: 2rem;
  }

  .custom-button {
    padding: 10px 18px;
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  .custom-caption {
    width: 80%;
    left: 15px;
  }

  .display-4 {
    font-size: 1.75rem;
  }

  .custom-button {
    padding: 8px 15px;
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .custom-caption {
    width: 90%;
    left: 10px;
    top: 60%;
  }

  .display-4 {
    font-size: 1.5rem;
  }

  .custom-button {
    padding: 6px 12px;
    font-size: 0.75rem;
    margin-right: 10px;
  }
}
