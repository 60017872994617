/* PricingPlan.css */
.pricing-plan {
  background-color: #6d28d9;
  color: white;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.3s ease;
  max-width: 40%;
  flex: 1;
}

.pricing-plan:hover {
  background-color: #0a4d68;
}

.pricing-plan h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.pricing-plan h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.pricing-plan hr {
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.pricing-plan ul {
  list-style-type: none;
  padding: 0;
}

.pricing-plan li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
}

.pricing-plan .check-mark {
  color: #fff; /* Green color for the check mark */
  margin-left: 10px;
}

.pricing-plan button {
  background-color: #ffffff;
  color: #0a4d68;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pricing-plan button:hover {
  background-color: #e0e0e0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pricing-plan {
    max-width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .pricing-plan {
    padding: 15px;
  }

  .pricing-plan h2 {
    font-size: 20px;
  }

  .pricing-plan h3 {
    font-size: 25px;
  }

  .pricing-plan li {
    font-size: 16px;
  }

  .pricing-plan button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
