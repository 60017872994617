AdminLogin.css

body {
    font-family: Arial, sans-serif;
    background-image: url('../images//background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .login-container {
    display: flex;
    justify-content: center;
    background-image: url('../images//background.jpg');
    align-items: center;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(10px);
  }
  
  .login-box {
    background-color: rgba(224, 224, 224, 0.9);
    padding: 40px 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: left;
  }
  
  h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .input-group label {
    margin-bottom: 5px;
    color: #555;
  }
  
  .input-wrapper {
    position: relative;
    width: 100%;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding-right: 30px;
  }
  
  .valid-icon,
  .error-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
  }
  
  .valid-icon {
    color: green;
  }
  
  .error-icon {
    color: red;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #757575;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #616161;
  }
  
  /* Responsive Design */
  @media (min-width: 768px) {
    .login-box {
      width: 70%;
      max-width: 400px;
    }
  }
  
  @media (min-width: 1024px) {
    .login-box {
      width: 50%;
      max-width: 400px;
    }
  }
  