/* Basic reset for consistent styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .contact-form {
    flex: 1;
    max-width: 600px;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    margin: 5px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h2 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #333;
  }
  
  .contact-form p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  .contact-form .input-with-icon {
    position: relative;
  }
  
  .contact-form .input-with-icon input {
    width: 100%;
    padding: 12px;
    padding-right: 40px; /* Adjust padding to make space for icon */
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form .valid-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: green;
  }
  
  .contact-form .error {
    color: red;
    font-size: 0.9em;
    margin-top: -15px;
    margin-bottom: 10px;
    display: block;
  }
  
  .contact-form input,
  .contact-form select,
  .contact-form textarea {
    width: 100%;
    padding: 12px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .contact-form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  
  .contact-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;
    margin: 5px;
  }
  
  .contact-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Responsive styles */
  @media (min-width: 768px) {
    .contact-container {
      flex-direction: row;
      align-items: center; /* Vertically centers the image */
    }
  
    .contact-form {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
  