.lets-discuss-section {
    background-color: #f39229; /* Orange background */
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    flex-wrap: wrap; /* Allows items to wrap on smaller screens */
    font-family: 'Poppins', sans-serif; /* Apply the font to the entire section */
  }
  
  .lets-discuss-content h2 {
    font-size: 24px;
    font-weight: 600; /* Use a bolder weight */
    margin: 0;
  }
  
  .consultancy-box {
    background-color: #285f69; /* Dark teal background for the box */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    flex: 1;
    max-width: 500px; /* Ensures the box takes up at least this much space */
  }
  
  .consultancy-box p {
    font-size: 18px;
    margin: 0 0 10px;
    color: white;
  }
  
  .consultancy-box a {
    display: inline-block;
    padding: 10px 20px;
    background-color: white; /* White background for the email box */
    color: #285f69; /* Dark teal color for the text */
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    border-radius: 4px; /* Rounded corners for the email box */
    margin-top: 10px;
  }
  
  .consultancy-box a:hover {
    background-color: #f39229; /* Orange background on hover */
    color: white; /* White text on hover */
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .lets-discuss-section {
      flex-direction: column; /* Stack elements vertically on smaller screens */
      text-align: center;
    }
  
    .lets-discuss-content h2 {
      font-size: 20px; /* Smaller font size for smaller screens */
    }
  
    .consultancy-box {
      margin-top: 20px; /* Add margin between sections */
    }
  }
  
  @media (max-width: 480px) {
    .lets-discuss-content h2 {
      font-size: 18px; /* Even smaller font size for mobile screens */
    }
  
    .consultancy-box p {
      font-size: 16px; /* Adjust font size for mobile screens */
    }
  
    .consultancy-box a {
      font-size: 14px; /* Adjust link size for mobile screens */
    }
  }
  