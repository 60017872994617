.services {
    text-align: center;
    padding: 40px 20px;
    background: linear-gradient(135deg, #f0f4f8, #e0e7ee);
    margin: 10px 0px;
  }
  
  .services h2 {
    font-size: 32px;
    margin-bottom: 40px;
  }
  
  .service-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  @media (max-width: 768px) {
    .service-card {
      flex: 1 1 calc(80% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .service-card {
      flex: 1 1 100%;
    }
  }
  