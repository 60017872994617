.service-container {
  background-color: #6d28d9; /* Initial background color */
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin: 10px;
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  /* Set initial background to transparent */
  overflow: hidden; /* Ensure the sliding effect is contained within the container */
  position: relative;
}

.service-container::before {
  content: '';
  position: absolute;
  top: -100%; /* Start position above the container */
  left: 0;
  width: 100%;
  height: 300px;
  background-color: black; /* Color to slide in */
  transition: top 0.5s ease;
}

.service-container:hover::before {
  top: 0; /* Slide in from top */
}

/* Ensure the container's content is above the sliding color */
.service-container h3{
  position: relative;
  z-index: 1;
}

/* Responsive styling */
@media (max-width: 768px) {
  .service-container {
    width: 100%;
  }
}
