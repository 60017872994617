/* Font and Design Enhancements */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

.about-section {
  display: flex;
  padding: 20px;
  background: linear-gradient(135deg, #f0f4f8, #e0e7ee);
  justify-content: center;
  gap: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.about-image, .about-content {
  width: 45%;
}

.aboutheading {
  font-size: 3em;
  font-weight: 700;
  color: #333;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  max-height: 650px; /* Set a maximum height for the image */
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;
}

.about-image img:hover {
  transform: scale(1.05);
}

.about-content {
  padding: 20px;
}

.about-content h1 {
  color: #333;
  font-size: 2.5em;
  margin-bottom: 15px;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.about-content p {
  color: #666;
  font-size: 1.2em;
  line-height: 1.8;
}

.about-buttons {
  margin-top: 20px;
}

.about-buttons .get-in-touch,
.about-buttons .our-services {
  padding: 12px 24px;
  margin-right: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  font-size: 1em;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-buttons .get-in-touch {
  background: linear-gradient(135deg, #007bff, #0056b3);
}

.about-buttons .our-services {
  background: linear-gradient(135deg, #28a745, #218838);
}

.about-buttons .get-in-touch:hover {
  background: linear-gradient(135deg, #0056b3, #004080);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.about-buttons .our-services:hover {
  background: linear-gradient(135deg, #218838, #1c7430);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Responsive Design */
@media (max-width: 1068px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }

  .about-image, .about-content {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    align-items: center;
  }
  
  .about-image, .about-content {
    width: 100%;
  }

  .about-image img {
    height: 350px;
  }

  .about-content {
    margin-top: 20px;
  }
}
