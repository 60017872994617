/* Footer.css */
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
  }
  
  .App {
    flex: 1;
  }
  
  .footer {
    background: linear-gradient(45deg, #222222 30%, #373839 90%);
    color: #ffffff;
    padding: 40px 20px;
    text-align: left;
    font-family: 'Arial', sans-serif;
  }
  
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-section {
    flex: 1;
    min-width: 250px;
    margin: 10px;
  }
  
  .footer-section h2 {
    width: 150px;
    font-size: 18px;
    margin-bottom: 10px;
    border-bottom: 2px solid #fbb117;
    padding-bottom: 5px;
  }
  
  .footer-section p,
  .footer-section ul,
  .footer-section li {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section li {
    margin-bottom: 5px;
  }
  
  .footer-section a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    /* border-top: 1px solid #fbb117; */
    padding-top: 10px;
  }
  
  .footer-bottom p {
    margin: 0;
    font-size: 14px;
    color: #fff;
  }
  
  /* Additional styling for better alignment and responsiveness */
  .footer-container {
    padding: 20px 0;
  }
  
  .footer-section h2 {
    margin-bottom: 15px;
  }
  
  .footer-section p {
    margin-bottom: 10px;
    color: #fbb117;
  }
  
  .footer-section ul {
    padding-left: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 8px;
  }
  
  .footer-section a {
    display: inline-block;
    margin-top: 5px;
  }
  
  .services-columns {
    display: flex;
  }
  
  .services-columns ul {
    margin: 0 10px;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .footer-section {
      margin-bottom: 20px;
    }
  
    .services-columns {
      flex-direction: column;
    }
  }
  