/* FeaturesDemoCall.css */
.features-demo-call {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    /* margin: 20px 0; */
    flex-wrap: wrap;
  }


  .features{
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    flex: 1;
    margin: 10px;
    max-width: 40%;
    height: 400px;
  }
  
  .demo-call {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    background-color: #6d28d9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    /* margin: 10px; */
    max-width: 40%;
    height: 400px;
  }

  .demo-call:hover{
    background-color: #0a4d68;
  }
  
  .features h2, .demo-call h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .features ul {
    list-style-type: none;
    padding: 0;
  }
  
  .features li {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .demo-call p {
    font-size: 18px;
    margin: 0;
  }
  
  @media (max-width: 768px) {



    .features-demo-call {
      flex-direction: column;
      align-items: center;
    }



    .features, .demo-call {
      max-width: 100%;
      margin: 10px 0;
    }
  }
  