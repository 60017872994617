.admin-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .nav-toggle {
    display: none;
    background: none;
    border: none;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
    position: fixed;
    top: 20px;
    margin-bottom: 80px;
    left: 20px;
    z-index: 1000;
  }
  
  .admin-nav {
    width: 250px;
    padding-top: 80px;
    background: linear-gradient(50deg, #0f0d27, #0b0b15, #19192d);
    color: #afb1b1;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 999;
  }
  
  .admin-nav.open {
    transform: translateX(0);
  }
  
  .admin-nav h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .admin-nav ul {
    list-style: none;
    padding: 0;
  }
  
  .admin-nav ul li {
    margin: 20px 0;
  }
  
  .admin-nav ul li a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  
  .nav-icon {
    margin-right: 10px;
  }
  
  .admin-nav ul li a:hover {
    text-decoration: underline;
  }
  
  .side-nav {
    display: none;
    width: 250px;
    background: linear-gradient(50deg, #0f0d27, #0b0b15, #19192d);
    color: #ecf0f1;
    padding-top: 50px;
    padding-left: 20px;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
  }
  
  .side-nav h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .side-nav ul {
    list-style: none;
    padding: 0;
  }
  
  .side-nav ul li {
    margin: 20px 0;
  }
  
  .side-nav ul li a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 18px;
    display: flex;
    align-items: center;
  }
  
  .side-nav ul li a:hover {
    text-decoration: underline;
  }
  
  .admin-content {
    width: 100%;
    flex: 1;
    padding: 20px;
    background: linear-gradient(50deg, #231f4b, #282848, #28284a);
    padding-top: 50px;
    margin-left: 250px; /* Sidebar width */
    transition: margin-left 0.3s ease;
    color: white;
  }
  
  /* Adjust the margin-left when sidebar is open */
  .admin-content.nav-open {
    margin-left: 0;
  }


  .contact-list-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(33, 27, 27);
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
  }








  




  
  /* Responsive Design */
  @media (max-width: 768px) {
    .admin-nav {
      width: 200px;
      padding-top: 70px;
      padding-left: 20px;
    }
  
    .nav-toggle {
      display: block;
    }
  
    .side-nav {
      display: none;
    }
  
    .admin-content {
      margin-left: 0;
    }
  }
  
  @media (min-width: 769px) {
    .admin-nav {
      display: none;
      padding-top: 70px;
      padding-left: 20px;
    }
  
    .side-nav {
      display: block;
    }
  }
  
  @media (max-width: 480px) {
    .admin-nav h2, .side-nav h2 {
      font-size: 20px;
    }
  
    .admin-nav ul li a, .side-nav ul li a {
      font-size: 16px;
    }
  }
  