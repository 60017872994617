.faq-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .faq-image {
    width: 100%;
    min-width: 400px;
    margin-bottom: 20px;
   height: 300px;
  }
  
  .questions-container {
    width: 100%;
    max-width: 50%;
  }
  
  .question-container {
    overflow: hidden; /* Ensure content stays within bounds */
  }
  
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    cursor: pointer;
    transition: transform 0.5s ease;
  }
  
  .question:hover {
    background-color: #f5f5f5;
  }
  
  .question-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .answer {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: height 0.5s ease, opacity 0.5s ease;
  }
  
  .answer.show {
    height: auto; /* Adjust this if you need to set a specific height */
    opacity: 1;
  }
  
  @media (min-width: 768px) {
    .faq-container {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  
    .faq-image {
      margin-bottom: 0;
      margin-right: 20px;
      max-width: 100%;
    }
  
    .questions-container {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .faq-container {
      padding: 10px;
    }
  
    .faq-image {
      max-width: 100%;
    }
  
    .questions-container {
      max-width: 100%;
    }
  
    .question {
      padding: 8px 0;
    }
  
    .question-title {
      font-size: 16px;
    }
  }
  