.vision-objective-mission {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    background: url('../images/banner-meeting-2b.jpg');
  }
  
  .section {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    margin: 10px;
    flex: 1 1 30%;
    height: 300px;
    box-sizing: border-box;
  }
  
  .icon {
    font-size: 40px;
    margin-bottom: 10px;
  }
  
  h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    color: #555;
  }
  
  @media (max-width: 768px) {
    .section {
      flex: 1 1 45%;
    }
  }
  
  @media (max-width: 480px) {
    .section {
      flex: 1 1 100%;
    }
  }
  