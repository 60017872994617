/* Ensure full height for the body and html */
html, body {
  height: 100%;
  margin: 0;
 
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.Header {
  /* Your header styling */
 
}

.Footer {
  margin-top: auto; /* Pushes the footer to the bottom */
  /* Your footer styling */
}


.pricingMasterContainer{
  display: flex;
  justify-content: center;
}




@media (max-width: 768px) {
  .pricingMasterContainer {
    flex-direction: column;
    align-items: center;
  }
}
