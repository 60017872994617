.parallex{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    color:#fff;
    font-size: 20px;
    background: url("../images/finance.webp");
    min-height: 400px; 
    /* font-weight: bold; */
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

#navigate{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
#navigate h3{
    /* padding-right: 5px; */
    font-size: 25px;
    font-family: 'Times New Roman', Times, serif;

}
#arrow{
    color: white;
    margin: 0px 3px;
    font-size: 20px;
}
h3{
    padding-bottom: 20px;
    
}

.parallex h1{
    text-align: center;
    font-size: 40px;
    padding-bottom: 20px;
    padding-top: 40px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif

}

.parallex p{
    font-family: 'Times New Roman', Times, serif;
    font-size: 20px;
}

@media screen and (max-width: 991px) {

.parallex{
    /* height: 120px; */
    min-height: 300px;
    padding-top: 0px;
    padding-bottom: 0px;
    

}


.parallex h1{
    text-align: center;
    font-size: 25px;
    padding-bottom: 10px;
    padding-top: 30px;

}

#navigate h3{
    /* padding-right: 5px; */
    font-size: 15px;
    font-family: 'Times New Roman', Times, serif;

}

.parallex p{
    font-family: 'Times New Roman', Times, serif;
    font-size: 10px;
    width: 80%;
}

}